<template lang="html">
<el-dialog
  title="账号详情"
  :visible.sync="dialogVisible"
  width="50%">
  <div>
  	<el-descriptions title="">
		    <el-descriptions-item label="员工ID">{{info.id}}</el-descriptions-item>
		    <el-descriptions-item label="登录账号">{{info.mobile}}</el-descriptions-item>
		    <el-descriptions-item label="真实姓名">{{info.name}}</el-descriptions-item>
		    <el-descriptions-item label="部门">{{info.dept}}</el-descriptions-item>
		    <el-descriptions-item label="qq">{{info.qq}}</el-descriptions-item>
		    <el-descriptions-item label="邮箱">{{info.email}}</el-descriptions-item>
		    <el-descriptions-item label="开通时间">{{info.createTime | dateTimeFormat}}</el-descriptions-item>
		    <el-descriptions-item label="开通人ID"></el-descriptions-item>
		    <el-descriptions-item label="开通人"></el-descriptions-item>
        <el-descriptions-item label="角色组ID"></el-descriptions-item>
		    <el-descriptions-item label="角色组"></el-descriptions-item>
		    
		    <el-descriptions-item label="账号状态">{{info.status ? '启用':'禁止'}}</el-descriptions-item>
        <el-descriptions-item label="备注">{{info.memo}}</el-descriptions-item>
		</el-descriptions>
		<div class="el-descriptions">
			<div class="el-descriptions__header">
				<div class="el-descriptions__title">账号动态</div>
			</div>
		</div>
		<el-table :data="tableData" border>
	    <el-table-column property="content" label="动态行为" width="150"></el-table-column>
	    <el-table-column property="time" label="动态时间" width="150"></el-table-column>
	    <el-table-column property="remark" label="动态备注"></el-table-column>
	  </el-table>

  </div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="cancel">关 闭</el-button>
  </span>
</el-dialog>
</template>

<script>
    export default {
    	data(){
    		return{
    			dialogVisible:false,
    			form:{
    				value:''
    			},
    			tableData:[]
    		}
    	},
      props:['info'],
    	components:{},
    	methods:{
    		cancel(){
    			this.dialogVisible = false
    		},
    	}
    }
</script>

<style scoped lang="scss">
	
</style>