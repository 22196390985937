<!-- 添加员工 -->
<template lang="html">
<el-dialog title="添加员工" :visible.sync="dialogVisible" width="600px">
  <el-form :model="form" label-width="100px">
    <el-form-item label="登录账号">
      <el-input v-model="form.mobile" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="真实姓名">
      <el-input v-model="form.name" autocomplete="off"></el-input>
    </el-form-item>
    <!-- <el-form-item label="部门">
      <el-input v-model="form.dept" autocomplete="off"></el-input>
    </el-form-item> -->
    <!-- <el-form-item label="角色分组">
      <el-select v-model="form.region" placeholder="请选择">
        <el-option label="超级管理员" value="1"></el-option>
        <el-option label="管理员" value="2"></el-option>
        <el-option label="运营组" value="3"></el-option>
      </el-select>
    </el-form-item> -->
    <el-form-item label="邮箱">
      <el-input v-model="form.email" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="QQ">
      <el-input v-model="form.qq" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="备注">
      <el-input v-model="form.memo" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="登录密码">
      <el-input v-model="form.password" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="状态">
      <el-select v-model="form.status" placeholder="请选择">
        <el-option label="禁用" :value="false"></el-option>
        <el-option label="启用" :value="true"></el-option>
      </el-select>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    
    <el-button type="primary" @click="ensure">添加员工</el-button>
    <el-button @click="cancel">关 闭</el-button>
  </div>
</el-dialog>
</template>

<script>
    export default {
    	data(){
    		return{
    			form:{},
    			dialogVisible:false
    		}
    	},
    	components:{},
    	methods:{
    		cancel(){
    			this.dialogVisible = false;
          this.form = {};
    		},
    		ensure(){
          this.$emit('ensure',this.form);
    			this.dialogVisible = false;
    		}
    	}
    }
</script>

<style scoped lang="scss">
.el-input{
	width:300px;
}
</style>