// 后台账号管理
<template>
  <div class="user-manage">
    <table-search :total="total" :conditions="conditions" @searchRes="searchRes" @refresh="getList">
      <template v-slot:btns>
        <div class="btns">
          <el-button type="primary" size="medium" @click="addHandle">添加账号</el-button>
        </div>
      </template>
    </table-search>
    <div class="table">
      <el-table
        :data="tableData"
        border
        @filter-change="filterChange"
        style="width: 100%">
        <el-table-column
          prop="id"
          sortable="custom"
          label="用户ID">
        </el-table-column>
        <el-table-column
          prop="mobile"
          label="登录账号">
        </el-table-column>
        <el-table-column
          prop="name"
          label="真实姓名">
        </el-table-column>
        <el-table-column
          prop=""
          label="角色别名">
        </el-table-column>
        <el-table-column
          prop=""
          label="角色组ID">
        </el-table-column>
        
        <el-table-column
          prop=""
          label="角色组名称">
          <template slot-scope="scope">
            <!-- <span :style="{'color':COLOR.orange}" v-show="scope.row.val==1">超级管理员</span>
            <span :style="{'color':COLOR.red}" v-show="scope.row.val==2">管理员</span> -->
          </template>
        </el-table-column>
        <el-table-column
          prop="dept"
          label="部门">
        </el-table-column>
        <el-table-column
          prop="status"
          :filters="statusFilter"
          column-key="status"
          label="状态">
          <template slot-scope="scope">
            <span :style="{'color':COLOR.green}" v-show="scope.row.status==1">有效</span>
            <span :style="{'color':COLOR.red}" v-show="scope.row.status==0">无效</span>
          </template>
        </el-table-column>
        <el-table-column
          width="340"
          prop="val"
          label="操作">
          <template slot-scope="scope">
              <el-button type="text" @click="checkHandle(scope.row)">查看详情</el-button>
              <el-divider direction="vertical"></el-divider>
              
              <el-button type="text" @click="editHandle(scope.row)">编辑</el-button>
              <el-divider direction="vertical"></el-divider>
              
              <el-button type="text" @click="userHandle(scope.row)">重置密码</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer flex-row">
      <div class="page">
        <el-pagination
          background
          :page-size="form.perPage"
          @size-change="handleSizeChange"
          layout="prev, pager, next,sizes,jumper"
          :page-sizes="[10, 20, 30, 40]"
          @current-change="currentChange"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <add-staff ref="addStaff" @ensure="addHandle2"></add-staff>
    <account-detail ref="accountDetail" :info="info"></account-detail>
    <edit-staff ref="editStaff" @ensure="editHandle2"></edit-staff>
  </div>
</template>

<script>
import TableSearch from '../../components/table-search.vue'
import AddStaff from './coms/add-staff.vue'
import AccountDetail from './coms/account-detail.vue'
import EditStaff from './coms/edit-staff.vue'
import {staffList,staffDetail,resetPassword,staffUpdate,staffAdd} from '../../api/system'
export default {
  name:'adminAccount',
  data () {
    return {
      total:0,
      form:{
        perPage:10,
        page:1,
        status:''
      },
      info:{},
      conditions:[
        {value:'name',label:'登录姓名'},
        {value:'mobile',label:'手机号'},
        {value:'id',label:'用户ID'},
      ],
      tableData:[

      ],
      multipleSelection:[],
        applyFilter:[
          {text: '诉讼业务', value: '1'}, 
          {text: '婚姻家庭', value: '2'}, 
          {text: '合同纠纷', value: '3'},
          {text: '非诉讼业务', value: '4'},
          {text: '法律顾问', value: '5'},
          {text: '劳动纠纷', value: '6'},
          {text: '金融证券', value: '7'},
          {text: '公司经营', value: '8'},
          {text: '法律文书', value: '9'},
        ],
        typeFilter:[
            {text: '等待选标中', value: '1'},
            {text: '已中标', value: '2'}, 
            {text: '未中标', value: '3'}
        ],
        subjectFilter:[
            {text: '超级管理员', value: '1'},
            {text: '管理员', value: '2'}, 
            {text: '技术部', value: '3'}, 
            {text: '运营组', value: '4'}, 
            {text: '财务组', value: '5'}, 
            {text: '产品', value: '6'}, 
        ],
        statusFilter:[
            {text: '有效', value: 1},
            {text: '无效', value: 0}
        ]

    };
  },
  components: {
    TableSearch,
    AddStaff,
    AccountDetail,
    EditStaff
  },
  computed: {},
  methods: {
    getList(){
      staffList(this.form).then(res=>{
        let data = res.data;
        if(data.code === '000'){
          this.tableData = data.content.list;
          this.total = data.content.total;
        }else{
          console.log(data.desc)
        }
      })
    },
    searchRes(para){
      this.form = Object.assign({
        page:1,
        perPage:this.form.perPage,
        status:this.form.status
        },para);
      this.getList();
    },
    // 分页
    currentChange(val){
      this.form.page = val;
      this.getList();
    },
    handleSizeChange(val){
      this.form.perPage = val;
      this.form.page = 1;
      this.getList();
    },
    addHandle(){
      this.$refs.addStaff.dialogVisible = true;
    },
    addHandle2(form){
      staffAdd(form).then(res=>{
        let data = res.data;
        if(data.code === '000'){
          this.$message.success(data.desc);
          this.getList();
        }else{
          this.$message.warning(data.desc);
        }
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(val)
    },
    checkHandle(row){
      staffDetail({id:row.id}).then(res=>{
        let data = res.data;
        if(data.code === '000'){
          this.info = data.content;
          this.$refs.accountDetail.dialogVisible = true;
        }else{
          console.log(data.desc)
        }
      })
    },
    userHandle(row){
      this.$prompt('请输入重置密码', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /^[0-9a-zA-Z]{6,}$/,
          inputErrorMessage: '请输入至少六位数字或者字母'
        }).then(({ value }) => {
          resetPassword({id:row.id,password: value}).then(res=>{
            let data = res.data;
            if(data.code === '000'){
              this.$message({
                type: 'success',
                message: data.desc
              })
            }else{
              this.$message.warning(data.desc)
            }
          })
        })
      // this.$confirm("确认重置后密码重置为初始密码：123456,是否继续？","提示",{
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(()=>{
      //   resetPassword({id:row.id,password:'123456'}).then(res=>{
      //   let data = res.data;
      //   if(data.code === '000'){
      //     this.$message({
      //       type: 'success',
      //       message: data.desc
      //     })
      //   }else{
      //     this.$message.warning(data.desc)
      //   }
      // })
        
      // })
    },
    editHandle(row){
      let form = this.$refs.editStaff.form;
      for(let key in form){
        form[key] = row[key];
      }
      this.$refs.editStaff.status2 = form.status;
      this.$refs.editStaff.dialogVisible = true;
    },
    editHandle2(form){
      staffUpdate(form).then(res=>{
        let data = res.data;
        if(data.code === '000'){
          this.$message.success(data.desc);
          this.getList();
        }else{
          console.log(data.desc)
        }
      })
    },
    forbidAll(){},
    forbid(){},
    filterChange(filters){
      for(let key in filters){
        this.form[key] = filters[key].join();
      }
      this.form.page = 1;
      this.getList();
    },
  },
  created(){
    this.getList();
  }
}

</script>
<style lang='scss' scoped>
.footer{
  justify-content: flex-end;
  margin:15px 0;
}
.dot{
  font-size: 20px;
  padding-right: 5px;
  vertical-align:middle;
}
</style>